import React, { useMemo, useCallback } from 'react';
import styled from 'styled-components';

import Icons from '../types/icons';
import RoundButton from './RoundButton';

const ShareButton = styled(RoundButton)`
  position: absolute;
  top: 1em;
  right: 1em;
`;

const Share = () => {
  const isNativeShareEnable = useMemo(() => !!navigator?.share, []);

  const openNativeShare = useCallback(
    () => navigator.share({ url: 'https://dilam.fr' }),
    []
  );

  return isNativeShareEnable ? (
    <ShareButton icon={Icons.SHARE} size={1.1} onClick={openNativeShare} />
  ) : null;
};

export default Share;
