import React from 'react';
import styled from 'styled-components';
import Header from './components/Header';
import Links from './components/Links';
import Share from './components/Share';

const Wrapper = styled.div`
  background-color: #dcd9d4;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    radial-gradient(
      at 50% 0%,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(0, 0, 0, 0.5) 50%
    );
  background-blend-mode: soft-light, screen;

  min-height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled.div`
  width: 100%;
  max-width: 600px;
`;

const FillSpace = styled.div`
  height: 100px;
`;

function App() {
  return (
    <Wrapper>
      <InnerWrapper>
        <Share />
        <Header />
        <Links />
        <FillSpace />
      </InnerWrapper>
    </Wrapper>
  );
}

export default App;
