import React, { useMemo } from 'react';
import styled from 'styled-components';

import Icons from '../types/icons';
import ExternalLink from './ExternalLink';

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  margin-top: 1em;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Links = ({ className }) => {
  const links = useMemo(
    () => [
      {
        icon: Icons.SOUNDCLOUD,
        label: 'SoundCloud',
        url: 'https://soundcloud.com/10lames',
      },
      {
        icon: Icons.TIKTOK,
        label: 'Tik Tok',
        url: 'https://www.tiktok.com/@10lames',
      },
      {
        icon: Icons.INSTAGRAM,
        label: 'Instagram',
        url: 'https://www.instagram.com/10lames/',
      },
      {
        icon: Icons.ZALANDO,
        label: 'Zalando',
        url: 'https://www.zalando.fr/creator/c884d8e8-b0b8-4836-ba29-a74798559f14/',
      },
      {
        label: 'Amazon Finds',
        url: 'https://www.amazon.fr/shop/10lames',
      },
    ],
    []
  );

  return (
    <Wrapper className={className}>
      {links.map((link, index) => (
        <ExternalLink key={`link-button-${index}`} {...link} />
      ))}
    </Wrapper>
  );
};

export default Links;
