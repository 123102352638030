import React from 'react';
import styled from 'styled-components';

import PPImg from '../assets/img/pp.jpg';
import Blob from './Blob';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerWrapper = styled.div`
  position: relative;
  width: 60%;
  max-width: 270px;
`;

const FloatingCentered = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfilePicture = styled.div`
  width: 67%;
  border-radius: 100%;
  border: 3px solid #42362a;
  box-shadow: inset 0 0 0 2px #ede7e1;

  background-image: url(${PPImg});
  background-size: cover;
  background-position: center;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  font-family: 'Magilio', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

const TitleIcon = styled.div`
  font-size: 1.3em;
  opacity: 0.5;
  font-weight: bold;
  margin-right: 0.1em;
`;

const Nickname = styled.div`
  color: #42362a;
  font-size: 2.5em;
`;

const NicknameNumber = styled.span`
  font-size: 0.83em;
`;

const Header = ({ className }) => {
  return (
    <Wrapper className={className}>
      <InnerWrapper>
        <Blob />
        <FloatingCentered>
          <ProfilePicture />
        </FloatingCentered>
      </InnerWrapper>
      <Title>
        <TitleIcon>@</TitleIcon>
        <Nickname>
          <NicknameNumber>10</NicknameNumber>lames
        </Nickname>
      </Title>
    </Wrapper>
  );
};

export default Header;
