import { createGlobalStyle } from 'styled-components';

import Magilio from '../assets/font/magilio.ttf';

const FontStyles = createGlobalStyle`
  @font-face {
    font-family: 'Magilio';
    src: url(${Magilio});
  }
`;

export default FontStyles;
