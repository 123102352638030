import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import {
  InstagramSvg,
  ShareSvg,
  SoundCloudSvg,
  TikTokSvg,
  ZalandoSvg,
} from '../assets/svg';
import Icons from '../types/icons';

const IconElement = styled.svg`
  width: 1em;
  height: 1em;
  color: white;

  ${({ $size, $color }) => css`
    width: ${$size}em;
    height: ${$size}em;
    color: ${$color};
  `}
`;

const Icon = ({ className, name, size, color }) => {
  const IconSvg = useMemo(() => {
    switch (name) {
      case Icons.INSTAGRAM:
        return InstagramSvg;
      case Icons.SHARE:
        return ShareSvg;
      case Icons.SOUNDCLOUD:
        return SoundCloudSvg;
      case Icons.TIKTOK:
        return TikTokSvg;
      case Icons.ZALANDO:
        return ZalandoSvg;
      default:
        return;
    }
  }, [name]);

  return (
    <IconElement
      as={IconSvg}
      className={className}
      $size={size}
      $color={color}
    />
  );
};

export default Icon;
