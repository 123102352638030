import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Wrapper = styled.a`
  text-decoration: none;
  position: relative;
  width: 100%;
  padding: 0.9em;
  box-sizing: border-box;
  border-radius: 10em;
  background: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-top: 1em;
  }

  transition: transform 200ms ease-in-out;

  &:active {
    transform: scale(0.95);
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 1em;
  font-size: 1.4em;
`;

const Label = styled.div`
  color: #555;
  font-weight: 500;
  letter-spacing: 1px;
`;

const ExternalLinkButton = ({ className, icon, label, url }) => {
  return (
    <Wrapper className={className} href={url} target='_blank' rel='noreferrer'>
      {icon ? <StyledIcon name={icon} color='#6d8b74' /> : null}
      <Label>{label}</Label>
    </Wrapper>
  );
};

export default ExternalLinkButton;
