import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

const Wrapper = styled.div`
  font-size: ${({ $size }) => $size}em;
  cursor: pointer;

  width: 2em;
  height: 2em;
  border-radius: 100%;
  background: #6d8b74;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: transform 200ms ease-in-out;

  &:active {
    transform: scale(0.95);
  }
`;

const RoundButton = ({ className, icon, size, onClick }) => (
  <Wrapper className={className} $size={size} onClick={onClick}>
    <Icon name={icon} />
  </Wrapper>
);

export default RoundButton;
